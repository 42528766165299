<template>
  <NotificationBase
    :notification
    :icon
    :compact
    link="/personal/transactions?tab=deposit"
  >
    <template #subject>
      {{ t('notifications.subject.questRewardAccrual') }}
    </template>
    <i18n-t keypath="notifications.body.questRewardAccrualDefault" tag="p">
      <template #amount>
        <b>{{ notificationAmount }}</b>
      </template>
    </i18n-t>
  </NotificationBase>
</template>

<script setup lang="ts">
import NotificationBase from '../NotificationBase.vue'
import type { AccountTransactionNotification } from '../../../../types'

const { notification, compact = false } = defineProps<{
  notification: AccountTransactionNotification
  compact?: boolean
}>()

const { t } = useI18n()

const { notificationAmount, currencyIcon } = useCurrencyNotification(
  toRef(() => notification),
)

const { useAccountIcon } = useAccountTransactionNotification(
  toRef(() => notification),
)

const icon = computed(() => ({
  name: useAccountIcon.value,
  currencyIcon: currencyIcon.value,
}))
</script>
